@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);
@import url(https://fonts.googleapis.com/earlyaccess/cwtexyen.css);
@import url(https://fonts.googleapis.com/earlyaccess/cwtexkai.css);

* {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans TC", "微軟正黑體", arial, "Microsoft JhengHei",
    sans-serif;
  /* font-family: "Helvetica", "Arial", "LiHei Pro", "黑體-繁", "微軟正黑體",
    sans-serif; */
  font-weight: 400;
}

html,
body {
  height: 100%;
  width: 100%;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  /* color: inherit; */
}

p,
li {
  /* line-height: 24px; */
  letter-spacing: 2px;
}

.background {
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: fit-content;
  max-height: 600px;
}

.float {
  font-size: 80px;
  color: white;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
  align-items: center;
}

.footer {
  background-color: #000;
}

.jumbotron {
  padding: 2rem 1rem;
  margin: 2rem 1rem;
  border-radius: 0.3rem;
  justify-content: center;
}

.smalltron {
  padding: 1rem 1rem;
  margin: 1rem 1rem;
  border-radius: 0.3rem;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 180px;
  border-radius: 50px;
}

.box-main {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  max-width: 80%;
  margin: auto;
  height: 80%;
}

.box-home {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin: auto;
  height: 60%;
}

h1,
h2 {
  letter-spacing: 3px;
  color: #c67983;
}

/* h3 {
  margin: auto;
} */

.home-rect {
  display: flex;
  justify-content: center;
}

.img {
  padding-top: 20px;
  padding-right: 70px;
  padding-bottom: 20px;
  padding-left: 70px;
  justify-content: center;
}

.carousel-wide {
  width: 800px;
  height: 600px;
  margin: auto;
}

.carousel-long {
  width: 600px;
  height: 800px;
  margin: auto;
}

@media (max-width: 900px) {
  .carousel {
    width: auto;
    height: auto;
  }
}

.content {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grid-block {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 200px;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.text-title {
  letter-spacing: 5px;
  font-weight: 500;
  font-size: 60px;
}

.text-mid {
  font-size: 20px;
}
@media screen and (min-width: 600px) {
  .text-mid {
    font-size: 20px;
  }
}

.text-small {
  font-size: 18px;
}

.text-content {
  font-size: 28px;
  font-weight: 400;
}

.text-thin {
  font-weight: 300;
}

.text-highlight {
  color: #d5683d;
}

.center {
  text-align: center;
}

.sticker {
  text-align: center;
  width: 200px;
  padding-bottom: 1rem;
  background-color: #d5683d;
  color: white;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.banner {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #9cb3c5;
  background-image: url(images/droplet_background.jpeg);
}

.text-banner {
  font-size: 48px;
  color: black;
  letter-spacing: 5px;
  text-align: center;
}

#root {
  margin-bottom: 0;
}

.kai {
  font-family: cwTeXKai;
  font-weight: 700;
}
