/* .navbar.collapse {
  justify-content: center;
} */

.nav-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* transition: 0.2s all ease-out !important; */
  color: white !important;
  font-size: large !important;
  justify-content: center;
  padding-block: 1rem;
}

/* .nav-link:hover {
  background-color: #e19475 !important;
  color: white !important;
} */

.nav-link.active {
  border-bottom: 7px solid white !important;
  background-color: inherit !important;
  box-shadow: none !important;
  color: white !important;
}

.dropdown.active {
  border-bottom: 7px solid white !important;
  background-color: inherit !important;
  box-shadow: none !important;
  cursor: pointer;
}

.dropdown-menu {
  min-width: 0px;
}

.dropdown-item {
  background-color: #c67983;
  color: white;
  border-bottom: 1px solid white;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* transition: 0.2s all ease-out !important; */
  font-size: large !important;
  justify-content: center;
}

.modal-open .offcanvas-body > .collapse {
  flex-direction: column;
}
