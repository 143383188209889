.table {
    margin: auto;
}

.table>thead{
    vertical-align: middle;
    text-align: center;
}

tr {
    vertical-align: middle;
    text-align: center;
}